// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bH_kz";
export var caseStudyBackground__lineColor = "bH_kq";
export var caseStudyHead__imageWrapper = "bH_kn";
export var caseStudyProjectsSection = "bH_kB";
export var caseStudyQuote__bgLight = "bH_kx";
export var caseStudyQuote__bgRing = "bH_kw";
export var caseStudySolution__ring = "bH_kt";
export var caseStudyVideo__ring = "bH_kF";
export var caseStudy__bgDark = "bH_km";
export var caseStudy__bgLight = "bH_kl";